import type { FC } from "react";

import { TableCell, TableRow } from "src/components";
import { useTranslation } from "src/translations";
import type { UserEarningsPayoutResponse } from "src/types";
import { getValOrThrowError, multiplyPriceInCents } from "src/utils";
import { EarningProjectCell } from "./EarningProjectCell";

type EarningItemProps = Readonly<{
    earning: UserEarningsPayoutResponse;
}>;

export const EarningItem: FC<EarningItemProps> = ({ earning }) => {
    const { t } = useTranslation();

    const { timestamp, projectId, amount, currency, settlement } = earning;
    const date = new Date(getValOrThrowError(timestamp, "Missing date for earning"));

    return (
        <TableRow className="font-inter text-base text-primary [&>td]:px-3 [&>td]:py-1.5 [&>td]:first:pt-3">
            <TableCell className="w-1/4">{t("format.localDate", { date })}</TableCell>
            <EarningProjectCell projectId={projectId!} />
            <TableCell className="w-1/4 text-right font-medium">
                {t("format.currency", { value: multiplyPriceInCents(amount), currency })}
            </TableCell>
            <TableCell className="w-1/4 text-right">{t(`earnings.item.settlementEnum.${settlement}`)}</TableCell>
        </TableRow>
    );
};
