import type { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useMemo, useState } from "react";

import { getCurrencyConversionRate } from "src/config";
import type { QueryErrors } from "src/serverApi";
import { getUserCurrency, useEarningsListQuery, useUserProjectPortfolioQuery } from "src/serverApi";
import type { Currency, UserPortfolio } from "src/types";
import { convertPriceToTargetCurrency, useAppSelector } from "src/utils";

type DashboardQueryData = {
    currency: Currency;
    setCurrency: (currency: Currency) => void;
    portfolio?: UserPortfolio;
    earnings?: number;
    isError: boolean;
    isLoading: boolean;
    error?: QueryErrors | SerializedError;
};

export const useDashboardQuery = (): DashboardQueryData => {
    const userCurrency = useAppSelector(getUserCurrency);
    const conversionRate = useAppSelector(getCurrencyConversionRate);
    const [currency, setCurrency] = useState(userCurrency);
    const { data: portfolio, isLoading, isError: isErrorPortfolio, error: portfolioError } = useUserProjectPortfolioQuery(currency);
    const { data: earnings, isError: isErrorEarnings, error: earningsError } = useEarningsListQuery();

    const convertPrice = useCallback(
        (price: number, priceCurrency: Currency) => convertPriceToTargetCurrency(price, priceCurrency, currency, conversionRate),
        [currency, conversionRate],
    );

    const [portfolioWithEarnings, totalEarnings] = useMemo<[UserPortfolio | undefined, number | undefined]>(() => {
        if (!portfolio || !earnings?.data) {
            return [portfolio, undefined];
        }

        const earningsSum = earnings.data.reduce((sum, earning) => sum + convertPrice(earning.amount, earning.currency), 0);

        const updatedProjects = portfolio.projects.map((project) => ({
            ...project,
            earnings:
                earnings.data
                    .filter((earning) => earning.projectId === project.id)
                    .reduce((sum, earning) => sum + convertPrice(earning.amount, earning.currency), project.earnings ?? 0) ?? 0,
        }));

        return [{ ...portfolio, projects: updatedProjects }, earningsSum];
    }, [earnings?.data, portfolio, convertPrice]);

    return {
        currency,
        setCurrency,
        portfolio: portfolioWithEarnings,
        earnings: totalEarnings,
        isLoading,
        isError: isErrorPortfolio || isErrorEarnings,
        error: portfolioError ?? earningsError,
    };
};
