import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useSetReferralCodeMutation } from "src/serverApi";
import { logger } from "./logger";

export const useSetReferralCode = () => {
    const [searchParams] = useSearchParams();
    const referral = searchParams.get("referral");

    const [setReferralCode] = useSetReferralCodeMutation();

    useEffect(() => {
        if (referral) {
            logger.debug("Setting referral code", referral);
            setReferralCode(referral)
                .unwrap()
                .catch((error) => {
                    logger.error("Failed to set referral code", error);
                });
        }
    }, [referral, setReferralCode]);
};
