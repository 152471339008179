import type { FC } from "react";
import Skeleton from "react-loading-skeleton";

import { ProjectLink, TableCell, TableRow } from "src/components";
import { useTranslation } from "src/translations";
import { multiplyPriceInCents } from "src/utils";
import type { DashboardProjectsItemProps } from "./types";

export const DashboardProjectsDesktopItem: FC<DashboardProjectsItemProps> = ({ project, currency }) => {
    const { t } = useTranslation();
    const { projectInfo, ownershipPercentage, tokenCount, currentValue, images, earnings, price } = project;
    const thumbnailUrl = images?.[0]?.url;

    return (
        <TableRow className="h-12 border-subtle font-inter text-base first:border-b [&>td]:py-3">
            <TableCell className="flex w-[450px] items-center gap-4 text-left">
                <ProjectLink projectSlug={projectInfo?.slug} projectTitle={projectInfo?.title} thumbnailUrl={thumbnailUrl} />
            </TableCell>
            <TableCell className="w-1/5 text-center">{t("format.percent", { value: ownershipPercentage })}</TableCell>
            <TableCell className="w-1/5 text-center">{t("format.amount", { value: tokenCount })}</TableCell>
            <TableCell className="w-1/5 text-right font-semibold">
                {t("format.currency", { value: multiplyPriceInCents(currentValue), currency })}
            </TableCell>
            <TableCell className="w-1/5 text-right font-semibold">
                {t("format.currency", { value: multiplyPriceInCents(price), currency })}
            </TableCell>
            <TableCell className="w-1/5 text-right font-semibold">
                {earnings === undefined ? (
                    <Skeleton className="h-[16px] max-w-[130px]" />
                ) : (
                    t("format.currency", { value: multiplyPriceInCents(earnings), currency })
                )}
            </TableCell>
        </TableRow>
    );
};
