import type { FC } from "react";

import { CmsError, CurrencySelector, NoContent, PageLayout } from "src/components";
import { useTranslation } from "src/translations";
import { BannerPageEnum, type Currency } from "src/types";
import { multiplyPriceInCents } from "src/utils";
import { DashboardProjects, DashboardStats } from "./components";
import { useDashboardQuery } from "./useDashboardQuery";

export const DashboardPage: FC = () => {
    const { t } = useTranslation();
    const { portfolio, currency, setCurrency, earnings, isLoading, isError, error } = useDashboardQuery();

    return (
        <PageLayout
            title={t("dashboard.title")}
            loading={isLoading}
            banner={BannerPageEnum.Dashboard}
            rightCornerComponent={
                <CurrencySelector value={currency} onChange={(value) => setCurrency(value as Currency)} contentPosition="popper" />
            }
        >
            {isError ? <CmsError error={error} fallbackMessage={t("common.error")} /> : null}
            <div className="flex flex-col gap-12 max-sm:gap-6">
                <div className="space-y-5 font-inter">
                    <span>{t("dashboard.netWorth.title")}</span>
                    <div className="text-6xl">{t("format.currency", { value: multiplyPriceInCents(portfolio?.netWorth), currency })}</div>
                </div>
                <DashboardStats portfolio={portfolio} currency={currency} earnings={earnings} />
                {portfolio?.projects && portfolio?.projects?.length > 0 ? (
                    <DashboardProjects projects={portfolio?.projects} currency={currency} />
                ) : (
                    <NoContent transKey={"dashboard.noContent"} />
                )}
            </div>
        </PageLayout>
    );
};
