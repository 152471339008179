import { HttpMethod } from "./constants";
import { rootApi } from "./rootApi";

export const referralApi = rootApi.injectEndpoints({
    endpoints: (build) => ({
        setReferralCode: build.mutation<void, string>({
            query: (programName) => ({
                url: `https://referral.investbay.com/set/${programName}`,
                method: HttpMethod.POST,
                credentials: "include",
            }),
        }),
        setEmailForReferralCode: build.mutation<void, string>({
            query: (email) => ({
                url: `https://referral.investbay.com/trigger/${email}/registration`,
                method: HttpMethod.POST,
                credentials: "include",
            }),
        }),
    }),
});

export const { useSetEmailForReferralCodeMutation, useSetReferralCodeMutation } = referralApi;
